$hero-img-height: 85;

.v-hp-hero {
  height: 56.25vw;

  &__background {
    transition: fill .6s ease-out;
  }

  &__slider {
    height: 100%;
  }

  &__track,
  &__slides {
    overflow: visible;
    height: 100%;
  }

  &__slides {
    width: 100vw !important;
    transform: translate3d(0,0,0) !important;
  }

  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    margin: 0 !important;
    opacity: 0;
    height: 100%;
    transition: opacity .6s ease-out;

    &.glide__slide--active {
      opacity: 1;
      z-index: 1;
    }
  }

  &__content {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
  }

  &__title {
    font-size: 2.281rem;
    max-width: 15.908592558em;
    background: #fff;
    margin: 0 0 1.875rem;
  }

  &__peaks-wrap {
    position: relative;
    z-index: 10;
    left: 50%;
    width: calc(100% + 4px);
    margin-top: -56%;
    pointer-events: none;
    transform: translateX(-50%);

    &::before {
      content: '';
      display: block;
      padding-bottom: 51.994996873%;
    }
  }

  &__peaks {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: visible;
	}

	&__slider-toggle {
		position: absolute;
		z-index: 999;
		top: calc(100vh - 100px);
		right: 50px;
		width: 50px;
		height: 50px;
		background: none;
		border: 0;

		@media screen and (max-aspect-ratio: 31/30) {
			top: calc(150px + 62.5vw); // header height + slider height
			right: 3vw;
		}

		@media screen and (max-width: $b-medium-large) and (max-aspect-ratio: 31/30) {
			top: calc(80px + 62.5vw); // header height + slider height
		}

		@media screen and (max-width: 650px) {
			top: calc(230px + 100vw);
			right: 10px;
		}

		&-label {
			@include a11y();
		}

		&-play,
		&-pause {
			width: 12px;
			height: 12px;
			transition: opacity .15s ease-out, transform .15s ease-out;
		}

		&:hover &-play,
		&:hover &-pause {
			opacity: .85;
			transform: scale(1.2);
		}

		&-play {
			display: none;
		}

		&--paused &-play {
			display: inline-block;
		}

		&--paused &-pause {
			display: none;
		}
	}

	.v-alert ~ &__slider-toggle {
		@media screen and (max-aspect-ratio: 31/30) {
			top: calc(150px + 86px + 62.5vw) // header height + approx. alert height + slider height
		}

		@media screen and (max-width: $b-medium-large) and (max-aspect-ratio: 31/30) {
			top: calc(80px + 86px + 62.5vw) // header height + approx. alert height + slider height
		}

		@media screen and (max-width: 650px) {
			top: calc(310px + 100vw);
		}
	}

  &__image {
    transition: opacity .6s ease-out;
  }

  &__bullets-wrap {
    position: absolute;
    top: 30%;
    left: 0;
    margin-top: -110px;
    width: 100%;
    z-index: 20;
  }

  &__bullets {
    display: flex;
    padding-left: 24px;
  }

  &__bullet {
    position: relative;
    z-index: 1;
    display: block;
    width: 80px;
    height: 80px;
    padding: 0;
    background: 0;
    border: 0;
    border-bottom: 40px solid currentColor;
    border-right: 40px solid currentColor;
    border-radius: 0;
    transform: translateY(-25%) rotate(45deg);
    opacity: .4;
    // animation: changeBulletOut 1s linear;
		// animation-iteration-count: infinite;
    // animation-fill-mode: both;

    & + & {
      margin-left: -24px;
    }

    &:nth-child(1) {
      color: #088c3f;
			animation: changeBulletOut 1s linear;
			animation-iteration-count: infinite;
			// animation-delay: 2s;
			animation-fill-mode: both;
    }

    &:nth-child(2) {
      color: $c-teal;
      z-index: 2;
      animation: changeBulletOut 1s linear;
			animation-iteration-count: infinite;
			// animation-delay: 1s;
			animation-fill-mode: repeat;
    }

    &:nth-child(3) {
      color: #0067b4;
			animation: changeBulletOut 1s linear;
			animation-iteration-count: infinite;
			animation-fill-mode: both;
    }

    &.glide__bullet--active {
      opacity: 1;
      z-index: 4;
      animation: changeBulletIn 1.6s linear;
			animation-iteration-count: infinite;
      animation-fill-mode: both;
    }
  }

  &__bullet-label {
    @include a11y();
	}

	&__pagination {
		font-size: 0;
		margin-top: 1.875rem;
		padding-top: .25rem;
	}

	&__pagination-bullet {
		padding: 0;
		width: 12px;
		height: 12px;
		margin: 0 5px;
		// background: $c-primary;
		background: #8fc4a6;
		border: 0;
		border-radius: 50%;
		// opacity: .5;
		cursor: pointer;
		transition: opacity .15s ease-out;

		&:hover {
			opacity: .85;
		}

		&--active {
			// opacity: 1;
			background: #0b3318;
		}
	}

	&__pagination-label {
		@include a11y();
	}

  &__mobile-peaks {
    display: none;
  }
}

@keyframes changeBulletIn {
  0% {opacity: .4; z-index: 1;}
  16.666666667% {opacity: .5; z-index: 4;}
  100% {opacity: 1; z-index: 4;}
}

@keyframes changeBulletOut {
  0% {opacity: 1; z-index: 4;}
  83.3333333% {opacity: .5; z-index: 1;}
  100% {opacity: .4; z-index: 1;}
}

@keyframes changeBulletOutMiddle {
  0% {opacity: 1; z-index: 4;}
  83.3333333% {opacity: .5; z-index: 2;}
  100% {opacity: .4; z-index: 2;}
}

@media screen and (min-aspect-ratio: 16/9) {
  .v-hp-hero {
    &__content {
      top: 25%;
    }

    &__bullets-wrap {
      top: 25%;
    }
  }
}

@media screen and (max-aspect-ratio: 16/9) {
  .v-hp-hero {
    &__content {
      top: 28vh;
    }

    &__peaks-wrap {
      width: 177vh;
    }

    &__bullets-wrap {
      top: 28vh;
    }
  }
}

@media screen and (max-aspect-ratio: 31/30) {
  .v-hp-hero {
    height: 62.5vw;

    &__content {
      top: 22.5vw;
    }

    &__peaks-wrap {
      width: 130%;
    }

    &__bullets-wrap {
      top: 22.5vw;
    }
  }
}

@media screen and (max-width: 1600px) {
  .v-hp-hero {
    &__title {
      font-size: calc(5px + 1.9375vw);
      margin-bottom: 1.5rem;
    }

    &__content {
      padding: 0 30px;
    }

    &__content .v-button {
      padding: calc(.75em + .296878125vw) calc(1em + 2.56246875vw);
      font-size: calc(10px + .3125vw);
    }

    &__bullets-wrap {
      padding: 0 30px;
      margin-top: calc((20px + 4.375vw) * -1);
    }

    &__bullets {
      padding-left: 1.3125vw;
    }

    &__bullet {
      width: 4.375vw;
      height: 4.375vw;
      border-bottom-width: 2.1875vw;
      border-right-width: 2.1875vw;

      & + & {
        margin-left: -1.3125vw;
      }
		}

		&__pagination {
			margin-top: 1.5rem;
		}
  }
}

/* @media screen and (max-width: $b-large) {
  .v-hp-hero {
    &__bullets-wrap {
      margin-top: calc((30px + 10px + 5.833333333vw) * -1);
    }

    &__bullet {
      width: calc(10px + 5.833333333vw);
      height: calc(10px + 5.833333333vw);
      border-bottom-width: calc(5px + 2.916666666vw);
      border-right-width: calc(5px + 2.916666666vw);

      & + & {
        margin-left: calc((3px + 1.75vw) * -1);
      }
    }
  }
} */

@media screen and (max-width: 700px) {
  .v-hp-hero {
    &__bullets-wrap {
      padding-left: 0;
      padding-right: 0;
    }

    &__content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 650px) {
  .v-hp-hero {
    height: calc(5.939602858rem + 75px + 11.538461539vw);

    &__content {
      padding: calc(30px + 8.461538462vw) 0 calc(45px + 3.076923077vw);
      top: 0;

      .v-button {
        @include body-zeta(false, $b-small);
        padding: .828571429em 2em;
      }
    }

    &__title {
      max-width: 100%;
      @include body-beta(true, 650px);
      margin-bottom: 1rem;
    }

    &__bullets-wrap {
			display: none;
      position: static;
      margin: calc(-2.307692308vw) 0 0;
    }

    &__bullets {
      padding: 0;
    }

    &__bullet {
      width: 12px;
      height: 12px;
      border: 0;
      border-radius: 50%;
      transform: none;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin: 0;
        background: $c-primary;
      }

      & + & {
        margin-left: 10px;
      }
		}

		&__pagination {
			margin-top: 1rem;
		}

    &__peaks-wrap {
      margin-top: calc(32.615384615% - 6vw);
    }

    &__image-mask {
      transform: scale(1.7) translate(-730px, -240px) !important;
    }

    &__peaks path {
      display: none;
    }

    &__mobile-peaks {
      display: block;
      position: relative;

/*       &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &::before {
        right: 50%;
        border-width: 50vw 0 0 50vw;
        border-color: transparent transparent transparent $c-primary;
      }

      &::after {
        left: 50%;
        border-width: 0 0 50vw 50vw;
        border-color: transparent transparent $c-primary transparent;
      } */

      &-inner {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &::before {
          content: '';
          display: block;
          padding-bottom: 50%;
        }
      }

      &-svg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__mobile-colors {
      transition: fill .6s ease-out;
    }
  }
}

@media screen and (max-width: $b-small) {
  .v-hp-hero {
    height: calc(7.462666667rem + 75px + 11.538461539vw);

    &__title {
      font-size: 1.4rem;
    }

    &__bullets-wrap {
      margin-top: calc(-25px + -2.307692308vw);
    }
  }
}

@media screen and (max-width: 350px) {
  .v-hp-hero {
    &__title {
      font-size: 1.2rem;
    }
  }
}