/* montserrat-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}
/* montserrat-500 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
		url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}

/* montserrat-600 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}
/* montserrat-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff')
			format('woff'),
		/* Modern Browsers */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../fonts/montserrat-v15-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.svg#Montserrat')
			format('svg'); /* Legacy iOS */
}
