.v-button {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 1.06667em 3.7333em;
  @include body-milli(false);
  line-height: 1.2;
  color: #fff;
  background: $c-secondary;
  // background: linear-gradient(to top, $c-secondary-a11y, $c-secondary);
  border: 0;
  box-shadow: none;
  border-radius: 1.66667em;
  font-weight: 600;
  transition: background-color .15s ease-out;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background: $c-secondary-hover;
  }

  &--primary {
    background: $c-primary;

    &:hover {
      background: $c-primary-hover;
    }
  }

  &--primary-dark {
    background: $c-primary-dark;

    &:hover {
      background: $c-primary-dark-hover;
    }
  }

  &--dark {
    background: #302e2c;

    &:hover {
      background: #22211f;
    }
  }

	&--sm {
		padding: .5em 1em;
	}
}

@media screen and (max-width: $b-small) {
  .v-button {
    @include body-zeta(false, $b-small);
    // padding: .858098571em 2.75em;
  }
}