.v-morph-product-grid {
  @include vspace-xsmall(false, true, padding);
  position: relative;
  z-index: 1;
  color: #fff;


  &::before {
    content: '';
    display: table;
  }

  &__background {
    color: $c-primary-dark;
  }

  body.single-personal &__background {
    color: $c-teal;
  }

  body.single-business &__background {
    color: #40638b;
  }

  &__inner {
    position: relative;
    z-index: 10;
  }

  &__content {
    margin-top: 26px;
  }

  &__title {
    color: $c-text;
    text-align: center;
    margin-bottom: 3rem;
  }

  &__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: -20px -10px;
  }

  &__item {
    width: 33.3333%;
    padding: 20px 10px 0;
    margin: 0;
  }

  &__link {
    display: block;
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }

  &__image {
		position: relative;
		// padding-bottom: 102px;
		padding-bottom: 132px;
    border: 7px solid #fff;
    overflow: hidden;
  }

	&--tall &__image {
		padding-bottom: 132px;
  }

  &__image-img {
		position: absolute;
    display: block;
    width: 100%;
		height: 100%;
    transition: transform .3s ease-out;
		object-fit: cover;
		object-position: 50% 25%;
  }

  &__link:hover &__image-img {
    transform: scale(1.06);
  }

  &__product-title {
    margin: 1em 0 0;
    @include body-milli;
    font-weight: 600;
    text-align: center;
  }
}

@media screen and (max-width: $b-large) {
  .v-morph-product-grid {
    &__inner {
    }
  }
}

@media screen and (max-width: $b-medium-large) {
  .v-morph-product-grid {
    &__inner {
    }

    &__item {
      width: 33.33333%;
    }
  }
}

@media screen and (max-width: $b-small) {
  .v-morph-product-grid {
    &__background {
      height: 100% !important;
      transform: none !important;
    }

    &__content {
      @include vspace-small(true, false, margin, 1, true, $b-small);
    }

    &__title {
      margin-bottom: 1rem;
    }

    &__list {
      display: block;
      margin: -10px 0;
    }

    &__item {
      width: 100%;
      padding: 10px 0;
    }

    &__image {
      position: relative;
      border-width: 10px;

      &::before {
        content: '';
        display: block;
        padding-bottom: 50.387596899%;
      }
    }

    &__image-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }

    &__product-title {
      margin-top: 0;
      padding: 5px 15px 15px;
      color: $c-primary-dark;
      background: #fff;
      font-size: 1rem;
    }
  }
}

@media ( orientation: portrait ) {
	.v-morph-product-grid {
		&__item {
			width: 50%;
		}

		&--tall &__image {
			// padding-bottom: calc(132px * 2);
		}
	}

	@media screen and (max-width: $b-small) {
		.v-morph-product-grid {
			&__item {
				width: 100%;
			}
		}
	}
}