.v-detail-insight {
  @include vspace-medium(true, false);
  position: relative;
	color: #fff;

	// for framer
	opacity: 0;

	&__header {
		@include vspace-xsmall(false, true);
		padding-left: 9%;
	}

  &__content {
		position: relative;
    padding: 3.25%;
		z-index: 11;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			max-width: 1200px;
			height: calc(100% - 200px);
			margin-top: 200px;
			background-color: $c-grey-light;
			z-index: -1;
			left: -5.72917vw;
		}

    a {
      color: inherit;
    }
  }

  &__shadow {
		position: absolute;
		top: 0;
		left: 0;
		height: 200px;
		width: 100%;
		opacity: .5;
		z-index: 1;
		background: #215732;
  }

	&__card-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	&__card {
		width: 100%;
		position: relative;
		background-color: #fff;
		padding: 7px 7px 0 7px;
		z-index: 11;
		margin-bottom: 14px;

		&:nth-of-type(odd) {
			margin-left: 0;
		}

		&:nth-of-type(even) {
			margin-right: 0;
		}
	}

	&__card-content {
		padding: calc(3.27510917% + 15px) calc(15px + 3.27510917%) calc(15px + 6.55021834%);

		& * {
			color: $c-text;
		}

		& table th,
		& table a,
		& table em
		& table strong {
			color: #fff;
		}
	}

	&__card-img-wrap {
		position: relative;
		overflow: hidden;
		height: 200px;
	}

	&__card-img {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
}
