@import './fonts';
@import './vars';
@import './normalize';
@import './utils/a11y';

/*-------------------------------------*\
  Typography
\*-------------------------------------*/

html {
	position: relative;
	font-size: $t-base-text;
	overflow-x: hidden;

	@media screen and (max-width: $b-small) {
		font-size: 15px;
	}
}

body {
	position: relative;
	font-family: $t-body-font;
	font-size: 100%;
	line-height: $t-line-height;
	color: #252525;
	background: #fff;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.full-body {
	overflow-x: hidden;

	body.page-template-template-security-center &,
	body.page-template-template-faqs & {
		overflow: visible;
	}
}

@media screen and (min-width: 1351px) {
	.full-body.login-open {
		padding-top: 112px;
	}
}

@media screen and (min-width: 1025px) {
	.full-body.search-open {
		padding-top: 112px;
	}
}

@media screen and (max-width: $b-medium-large) {
	html.mobile-nav-open {
		overflow-y: scroll;
	}

	body.mobile-nav-open {
		height: 100vh;
		overflow: hidden;
	}
}

#top {
	@include msedge_ie11 {
		overflow: hidden;
	}
}

p,
ul,
ol,
dl,
table,
figure,
blockquote,
address,
h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon,
.zeta {
	margin-top: 0;
	margin-bottom: 1.25rem;
}

/* p,
ul,
ol,
dl,
table,
figure,
address {
  font-size: 1rem;
} */

h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon,
.zeta {
	font-family: $t-heading-font;
	line-height: 1.2;
	margin-top: 2.5rem;
	font-weight: 700;

	& + h1,
	& + h2,
	& + h3,
	& + h4,
	& + h5,
	& + h6,
	& + .alpha,
	& + .beta,
	& + .gamma,
	& + .delta,
	& + .epsilon,
	& + .zeta {
		margin-top: 0;
	}

	sup {
		font-size: 0.4em;
		vertical-align: 0.75em;
		margin-left: 0.5em;
	}
}

sup {
	cursor: pointer;
	text-decoration: underline;
}

.v-disclosure sup {
	cursor: default;
	text-decoration: none;
}

h1,
.alpha {
	@include body-alpha;

	@media screen and (max-width: 1440px) {
		font-size: 2.281rem;
	}

	@media screen and (max-width: $b-medium) {
		@include body-alpha(true, $b-medium);
	}
}

h2,
.beta {
	@include body-beta;

	@media screen and (max-width: $b-small) {
		@include body-gamma(true, $b-small);
		line-height: 1.38889;
	}
}

h3,
.gamma {
	@include body-gamma;
}

h4,
.delta {
	@include body-delta;
}

h5,
.epsilon {
	@include body-epsilon;
}

h6,
.zeta {
	@include body-zeta;
}

a {
	color: $c-primary;
	color: #03833e;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

address {
	font-style: normal;
}

ul,
ol {
	padding-left: 1rem;

	li {
		margin: 0.75em 0;
	}

	ul,
	ol {
		margin-top: 0.5em;

		li {
			margin: 0.5em 0;
		}
	}
}

blockquote {
	footer {
		font-weight: 700;
		text-transform: uppercase;

		cite {
			font-style: normal;
		}
	}
}

.v-callout {
	@include body-delta;
	font-weight: 400;
	line-height: 1.47826087;
}

.v-bordered {
	padding: 10px 18px;
	border: 2px solid #ebebeb;
	margin: 0 0 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}

@media screen and (max-width: $b-small) {
	.v-callout {
		font-weight: 500;
	}
}

/*-------------------------------------*\
  Forms
\*-------------------------------------*/

.v-password-protection-label.v-password-protection-label {
	display: block;
	margin: 2rem 0 0;
}

.v-password-protection-input {
	margin: 1rem 0;
}

[type='text'],
[type='date'],
[type='number'],
[type='email'],
[type='tel'],
[type='search'],
[type='color'],
[type='password'],
[type='time'],
[type='url'],
select,
textarea {
	display: block;
	width: 100%;
	height: 60px;
	font: inherit;
	font-size: 16px;
	color: #215732;
	padding: 0 1.25em;
	background: none;
	border: 1px solid #ccc;
	border-radius: 5px;

	&:focus {
		border-color: $c-primary;
		outline: 0;
	}
}

textarea {
	resize: vertical;
	height: 11em;
	padding: 1em;
}

button,
label[for],
[type='submit'] {
	cursor: pointer;
}

.v-form {
	list-style: none;
	margin: -8px -16px;
	padding: 0;
	font-size: 0;

	br {
		display: none;
	}

	&__field {
		display: inline-block;
		width: 100%;
		vertical-align: top;
		font-size: 1rem;
		padding: 8px 16px;
		margin: 0;

		&--half {
			width: 50%;

			@media screen and (max-width: $b-small) {
				width: 100%;
			}
		}

		&--third {
			width: 33.3333%;

			@media screen and (max-width: $b-medium) {
				width: 100%;
			}
		}

		&--quarter {
			width: 25%;

			@media screen and (max-width: $b-medium) {
				width: 50%;
			}

			@media screen and (max-width: $b-small) {
				width: 100%;
			}
		}

		&--full-at-medium-large {
			@media screen and (max-width: $b-medium-large) {
				width: 100%;
			}
		}

		&--half-at-medium-large {
			@media screen and (max-width: $b-medium-large) {
				width: 50%;
			}

			@media screen and (max-width: $b-small) {
				width: 100%;
			}
		}

		&--full-at-medium {
			@media screen and (max-width: $b-medium) {
				width: 100%;
			}
		}

		&--push-center {
			text-align: center;
		}
	}

	div[data-class='wpcf7cf_group'] {
		display: none;
		width: 50%;
		vertical-align: top;
		font-size: 1rem;
		padding: 8px 16px;
		margin: 0;

		&.open {
			display: inline-block;
		}

		@media screen and (max-width: $b-medium) {
			width: 100%;
		}
	}

	&__field-pushed {
		display: inline-block;
		text-align: left;
	}

	&__select {
		position: relative;
		display: block;
		border: 0;
		background: #fff;
		color: #215732;
		border-radius: 5px;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			right: 1em;
			margin-top: -9px;
			width: 10px;
			height: 10px;
			border-bottom: 1px solid #215732;
			border-right: 1px solid #215732;
			transform: scaleX(0.8) rotate(45deg);
			pointer-events: none;
		}

		select {
			border: 0;
			padding: 0 calc(13px + 2em) 0 1.25em;
			appearance: none;
			color: #215732;
			background: none;

			&::-ms-expand {
				display: none;
			}

			&:focus {
				background: rgba(0, 0, 0, 0.06);
			}
		}
	}

	&__radio {
		position: relative;
		display: inline-block;
		width: 14px;
		height: 14px;
		margin-right: 0.5rem;
		vertical-align: middle;
		background: #fff;
		border: 1px solid #c8c6cb;
		border-radius: 50%;
		transition: border 0.15s ease-out;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 6px;
			height: 6px;
			background: #356c7b;
			border-radius: 50%;
			transform: translate(-50%, -50%) scale(0.4);
			opacity: 0;
			transition: transform 0.15s ease-out, opacity 0.15s ease-out;
		}

		&:hover::before {
			opacity: 0.5;
			transform: translate(-50%, -50%) scale(0.7);
		}
	}

	@at-root:focus ~ &__radio {
		border: 2px solid #356c7b;
	}

	@at-root:checked ~ &__radio::before {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}

	&__checkbox {
		position: relative;
		display: inline-block;
		width: 15px;
		height: 15px;
		margin-right: 0.5rem;
		vertical-align: middle;
		background: #fff;
		border: 1px solid #ccc;
		transition: border 0.15s ease-out;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 9px;
			height: 6px;
			border-bottom: 2px solid $c-primary-dark;
			border-left: 2px solid $c-primary-dark;
			opacity: 0;
			transform: translate(-50%, -50%) translate(0.5px, -2px) rotate(-55deg)
				scale(0.4);
			transition: transform 0.15s ease-out, opacity 0.15s ease-out;
		}
	}

	@at-root:focus ~ &__checkbox {
		border: 2px solid $c-primary-dark;
	}

	@at-root:checked ~ &__checkbox::before {
		opacity: 1;
		transform: translate(-50%, -50%) translate(0.5px, -2px) rotate(-55deg)
			scale(1);
	}

	&__label-text {
		display: block;
		font-size: 0.75rem;
		font-weight: 400;
		line-height: 1.3;
		margin-bottom: 0.5rem;

		&--hidden {
			@include a11y();
		}

		&--float {
			opacity: 0;
			transform: translateY(10px);
			transition: transform 0.3s ease-out, opacity 0.3s ease-out;
		}

		&--float-up {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.wpcf7-list-item {
		display: block;
		font-size: 0.75rem;
		margin-left: 0;
	}
}

[type='checkbox'].hidden {
	@include a11y();
}

[type='radio'].hidden {
	@include a11y();
}

[type='checkbox']:focus + .v-form__checkbox {
	outline: 1px dotted currentColor;
}

.facetwp-facet {
	position: relative;
	display: block;
	border: 0;
	color: #215732;
	border-radius: 5px;

	&-arrow {
		position: absolute;
		top: 50%;
		right: 1em;
		margin-top: -9px;
		width: 10px;
		height: 10px;
		border-bottom: 1px solid #fff;
		border-right: 1px solid #fff;
		transform: scaleX(0.8) rotate(45deg);
	}

	select {
		border: 0;
		padding: 0 calc(13px + 2em) 0 1.25em;
		appearance: none;
		color: #215732;
		background: none;

		&::-ms-expand {
			display: none;
		}

		&:focus {
			background: rgba(0, 0, 0, 0.06);
		}
	}
}

@media screen and (max-width: $b-small) {
	[type='text'],
	[type='date'],
	[type='number'],
	[type='email'],
	[type='tel'],
	[type='search'],
	[type='color'],
	[type='password'],
	[type='time'],
	[type='url'],
	select,
	textarea {
		height: 50px;
	}

	textarea {
		height: 9em;
	}
}

/*-------------------------------------*\
  Tables
\*-------------------------------------*/

table {
	max-width: 100%;
	border-collapse: collapse;
}

/*-------------------------------------*\
  Utilities and Helpers
\*-------------------------------------*/

[class^='v-icon-'],
[class*=' v-icon-'] {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

.v-icon-linkedin {
	width: 0.857421875em;
}

.v-icon-twitter {
	width: 0.9287109375em;
}

.v-icon-facebook {
	width: 0.587890625em;
}

.v-icon-search {
	width: 0.96875em;
}

.clearfix::after {
	content: '';
	display: block;
	clear: both;
}

.responsive-embed {
	position: relative;

	&::before {
		content: '';
		display: block;
		padding-bottom: 56.25%;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.alignleft,
.alignright,
.aligncenter,
.alignnone {
	margin-bottom: 1.25rem;
}

.alignleft {
	float: left;
	margin-right: 10px;
}

.alignright {
	float: right;
	margin-left: 10px;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

:first-child {
	margin-top: 0;
}

:last-child {
	margin-bottom: 0;
}

.v-skip-nav {
	position: fixed;
	top: -40px;
	left: 0;
	z-index: 99999;
	width: 100%;
	height: 40px;
	background: $c-primary-dark;
	color: #fff;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover,
	&:focus {
		top: 0;
		background: $c-primary-dark-hover;
		text-decoration: none;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 11px rgba(0, 0, 0, 0.1),
			0 0 20px rgba(0, 0, 0, 0.1);
	}
}

@media screen and (max-width: $b-small) {
	.alignleft,
	.alignright {
		float: none;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

/*-------------------------------------*\
  Tables
\*-------------------------------------*/

.v-table,
table {
	width: calc(100% - 4px);
	margin-left: 2px;
	margin-right: 2px;
	border: 3px solid #fff;
	border-collapse: collapse;
	box-shadow: 0 0 0 2px #ebebeb;
	color: $c-text;

	display: block;
	overflow: hidden;

	caption {
		display: none;
	}

	thead {
		color: #fff;
		background: #84736c;
	}

	th,
	td {
		text-align: left;
		border: 2px solid #fff;
		padding: calc(1.343312224% + 10px);
	}

	th {
		@include body-micro;
		line-height: 1.285714286;
	}

	td {
		@include body-milli();
		line-height: 1.712666667;

		a.v-button {
			margin-top: 1rem;
		}
	}

	tbody tr:nth-child(1n) {
		background: #f8f7f6;
	}

	tbody tr:nth-child(2n) {
		background: #fbfbfb;
	}

	tbody th {
		color: #fff;
		background: $c-primary-dark;

		a {
			color: inherit;
			text-decoration: underline;

			&:focus {
				outline-color: currentColor;
			}

			&:hover {
				opacity: 0.8;
				color: inherit;
			}
		}
	}

	@at-root body.single-personal & tbody th {
		background: #008476;
	}

	@at-root body.single-business & tbody th {
		background: #40638b;
	}

	tbody th,
	tbody td {
		vertical-align: top;
	}

	.v-button {
		padding-left: 2em;
		padding-right: 2em;
		white-space: nowrap;
	}
}

// REMOVING FOR DIGITAL BAR / KIOSK Tablet station

// @media screen and (max-width: 1080px) {
// 	.v-table--large {
// 		display: block;

// 		tbody,
// 		tfoot,
// 		tr,
// 		th,
// 		td {
// 			display: block;
// 			width: 100% !important;
// 		}

// 		thead {
// 			display: none;
// 		}

// 		th,
// 		td {
// 			border: 0;
// 			padding: 7.5px 25px;

// 			&:first-of-type {
// 				padding-top: 15px;
// 			}

// 			&:last-of-type {
// 				padding-bottom: 15px;
// 			}
// 		}

// 		td::before {
// 			content: attr(data-header);
// 			display: block;
// 			font-weight: 600;
// 		}
// 	}
// }

// @media screen and (max-width: 900px) {
// 	.v-table {
// 		display: block;

// 		tbody,
// 		tfoot,
// 		tr,
// 		th,
// 		td {
// 			display: block;
// 			width: 100% !important;
// 		}

// 		thead {
// 			display: none;
// 		}

// 		th,
// 		td {
// 			border: 0;
// 			padding: 7.5px 25px;

// 			&:first-of-type {
// 				padding-top: 15px;
// 			}

// 			&:last-of-type {
// 				padding-bottom: 15px;
// 			}
// 		}

// 		td::before {
// 			content: attr(data-header);
// 			display: block;
// 			font-weight: 600;
// 		}
// 	}
// }

/*-------------------------------------*\
  Layout
\*-------------------------------------*/

.container {
	width: 100%;
	@include maxwidth-wrapper;
	@include hspace-wrapper;
	// padding: 0 24px;
	margin: 0 auto;
}

.v-main {
	background: #f7f7f7;

	&::after {
		content: '';
		display: table;
		clear: both;
	}

	&--white {
		background: none;
	}
}

/*-------------------------------------*\
  For React App
\*-------------------------------------*/
.app {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	// max-width: 1200px;
	margin: 0 auto;
	position: relative;

	&::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: -1;
		background: #e9e9e9;
	}
}

.v-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	margin-top: .5rem;
	text-align: center;
	z-index: 1;
}

.v-404 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	a {
		color: #fff;
		text-decoration: none;
	}
}

.v-vitamin {
	@include vspace-medium;

	&__link {
		color: #fff;
		padding-bottom: 2px;
		border-bottom: 1px solid #fff;

		&:hover {
			text-decoration: none;
			border-bottom: none;
		}
	}
}

/*-------------------------------------*\
  Components
\*-------------------------------------*/

@import './components/header.scss';
@import './components/main.scss';
@import './components/disclosure.scss';
@import './components/footer.scss';
@import './components/button.scss';
@import './components/morph-product-grid.scss';
@import './components/intro.scss';
@import './components/hero-hp.scss';
@import './components/slider.scss';
@import './components/detail-two-column.scss';
@import './components/detail-comparison.scss';
@import './components/detail-insight.scss';
@import './components/detail-no-hero.scss';
@import './components/detail-two-column.scss';
@import './components/list-insight.scss';
@import './components/content-table.scss';
@import './components/accordion-blocks.scss';