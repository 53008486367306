.v-header {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	background: #fff;
	z-index: 12;

	& .container {
		height: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
	}

	&__back-btn {
		// @include hspace-wrapper(false, false, margin)
		@include body-milli;
		margin-left: 24px;
		font-size: .875rem !important;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);

		@media screen and (min-width: 1200px) {
			// left: calc(50% - 600px);
			transform: translateY(-50%);
		}
	}

	&__logo-wrap {
		padding: 50px;
		width: 200px
	}
}