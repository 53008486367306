.v-footer {
	display: flex;
	justify-content: space-between;
	text-align: center;
	color: #fff;
	background-color: $c-grey-dark;
	z-index: 1;
	padding: .5rem 24px;

	p {
		margin-bottom: 0;
	}

	&__text {
		display: flex;
		align-items: center;
	}

	&__icon {
		margin-left: 9px;
		width: 24px;
		height: 17px;
	}
}

@media screen and (max-width: 900px) {
	.v-footer {
		flex-direction: column;
		justify-content: center;

		&__text {
			margin: 0 auto;
		}
	}
}