@import 'utils/helpers';
@import 'utils/locked-spacing';
@import 'utils/responsive-type';
@import 'utils/a11y';
@import 'utils/ie11-edge';

// ---- Variables

// Common Breakpoints
$b-small: 550px;
$b-medium: 768px;
$b-medium-large: 1024px;
$b-large: 1200px;
$b-maxspacing: 1920px;
$b-minspacing: 360px;

// Typography
$t-body-font: 'montserrat', -apple-system, system-ui, BlinkMacSystemFont,
	'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol';
$t-heading-font: 'montserrat', -apple-system, system-ui, BlinkMacSystemFont,
	'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol';
$t-base-text: 16px;
$t-base-text-sm: 15px;
$t-line-height: 1.6875;
$t-type-scale-l: 1.25;
$t-type-scale-s: 1.25;

// Colors
// $c-primary: #039748; // original color
$c-primary: #038740;
$c-primary-hover: #007a39;
// $c-secondary: #c88a3e; // original color
// $c-secondary-a11y: #ae6307; // first-pass modified color
$c-secondary: #a46828;
// $c-secondary-hover: #bd7f33; // original color
$c-secondary-hover: #9b6123;
$c-primary-dark: #366746;
$c-primary-dark-hover: #2e5c3d;
$c-grey: #302e2c;
$c-grey-hover: #272523;
$c-grey-dark: #222222;
$c-grey-light: #f7f7f7;
$c-blue: #0072bc;
$c-teal: #01796b;
$c-text: #302e2c;

// Misc
$max-content: 1410px;

// ---- Mixins

// Type sizing
/* $type-scale-step-giga-l: 9;
$type-scale-step-giga-s: 3;
$body-giga-l: pow($t-type-scale-l, $type-scale-step-giga-l) * 1rem;
$body-giga-s: pow($t-type-scale-s, $type-scale-step-giga-s) * 1rem;

@mixin body-giga($media: true, $current: 9999) {
  @include responsive-type($body-giga-l, $body-giga-s, $media, $current);
}

$type-scale-step-mega-l: 8;
$type-scale-step-mega-s: 3;
$body-mega-l: pow($t-type-scale-l, $type-scale-step-mega-l) * 1rem;
$body-mega-s: pow($t-type-scale-s, $type-scale-step-mega-s) * 1rem;

@mixin body-mega($media: true, $current: 9999) {
  @include responsive-type($body-mega-l, $body-mega-s, $media, $current);
}

$type-scale-step-kilo-l: 7;
$type-scale-step-kilo-s: 5;
$body-kilo-l: pow($t-type-scale-l, $type-scale-step-kilo-l) * 1rem;
$body-kilo-s: pow($t-type-scale-s, $type-scale-step-kilo-s) * 1rem;

@mixin body-kilo($media: true, $current: 9999) {
  @include responsive-type($body-kilo-l, $body-kilo-s, $media, $current);
} */

$type-scale-step-alpha-l: 5;
$type-scale-step-alpha-s: 2;
$body-alpha-l: 2.5658125rem;
$body-alpha-m: 2.03125rem;
$body-alpha-s: 1.6rem;

@mixin body-alpha($media: true, $current: 9999) {
	@include responsive-type(
		$body-alpha-l,
		$body-alpha-m,
		$body-alpha-s,
		$media,
		$current
	);
}

$type-scale-step-beta-l: 4;
$type-scale-step-beta-s: 1;
$body-beta-l: 1.8020625rem;
$body-beta-m: 1.59375rem;
$body-beta-s: 1.5rem;

@mixin body-beta($media: true, $current: 9999) {
	@include responsive-type(
		$body-beta-l,
		$body-beta-m,
		$body-beta-s,
		$media,
		$current
	);
}

$type-scale-step-gamma-l: 3;
$type-scale-step-gamma-s: 0.53;
$body-gamma-l: 1.6018125rem;
$body-gamma-m: 1.34375rem;
$body-gamma-s: 1.2rem;

@mixin body-gamma($media: true, $current: 9999) {
	@include responsive-type(
		$body-gamma-l,
		$body-gamma-m,
		$body-gamma-s,
		$media,
		$current
	);
}

$type-scale-step-delta-l: 2;
$type-scale-step-delta-s: 0.53;
$body-delta-l: 1.4238125rem;
$body-delta-m: 1.21875rem;
$body-delta-s: 1.066666667rem;

@mixin body-delta($media: true, $current: 9999) {
	@include responsive-type(
		$body-delta-l,
		$body-delta-m,
		$body-delta-s,
		$media,
		$current
	);
}

$type-scale-step-epsilon-l: 1;
$type-scale-step-epsilon-s: 0;
$body-epsilon-l: 1.265625rem;
$body-epsilon-m: 1.125rem;
$body-epsilon-s: 1.066666667rem;

@mixin body-epsilon($media: true, $current: 9999) {
	@include responsive-type(
		$body-epsilon-l,
		$body-epsilon-m,
		$body-epsilon-s,
		$media,
		$current
	);
}

$type-scale-step-zeta-l: 0.53;
$type-scale-step-zeta-s: 0;
$body-zeta-l: 1.125rem;
$body-zeta-m: 1rem;
$body-zeta-s: 0.9333333rem;

@mixin body-zeta($media: true, $current: 9999) {
	@include responsive-type(
		$body-zeta-l,
		$body-zeta-m,
		$body-zeta-s,
		$media,
		$current
	);
}

$type-scale-step-milli-l: 0.53;
$type-scale-step-milli-s: 0;
$body-milli-l: 0.9375rem;
$body-milli-m: 0.875rem;
$body-milli-s: 0.866666667rem;

@mixin body-milli($media: true, $current: 9999) {
	@include responsive-type(
		$body-milli-l,
		$body-milli-m,
		$body-milli-s,
		$media,
		$current
	);
}

$type-scale-step-micro-l: 0.53;
$type-scale-step-micro-s: 0;
$body-micro-l: 0.875rem;
$body-micro-m: 0.8125rem;
$body-micro-s: 0.8rem;

@mixin body-micro($media: true, $current: 9999) {
	@include responsive-type(
		$body-micro-l,
		$body-micro-m,
		$body-micro-s,
		$media,
		$current
	);
}

$type-scale-step-nano-l: 0.53;
$type-scale-step-nano-s: 0;
$body-nano-l: 0.8125rem;
$body-nano-m: 0.75rem;
$body-nano-s: 0.7333333rem;

@mixin body-nano($media: true, $current: 9999) {
	@include responsive-type(
		$body-nano-l,
		$body-nano-m,
		$body-nano-s,
		$media,
		$current
	);
}

// Spacing
$vspace-xlarge-max: 165px;

@mixin vspace-xlarge(
	$top: true,
	$bottom: true,
	$type: margin,
	$modifier: 1,
	$media: true,
	$current: 9999
) {
	@include locked-spacing(
		$top,
		$bottom,
		$type,
		$modifier,
		$media,
		$current,
		$vspace-xlarge-max,
		'vertical',
		$b-small,
		80px
	);
}

$vspace-large-max: 145px;

@mixin vspace-large(
	$top: true,
	$bottom: true,
	$type: margin,
	$modifier: 1,
	$media: true,
	$current: 9999
) {
	@include locked-spacing(
		$top,
		$bottom,
		$type,
		$modifier,
		$media,
		$current,
		$vspace-large-max,
		'vertical',
		$b-small,
		60px
	);
}

$vspace-medium-max: 110px;

@mixin vspace-medium(
	$top: true,
	$bottom: true,
	$type: margin,
	$modifier: 1,
	$media: true,
	$current: 9999
) {
	@include locked-spacing(
		$top,
		$bottom,
		$type,
		$modifier,
		$media,
		$current,
		$vspace-medium-max,
		'vertical',
		$b-small,
		55px
	);
}

$vspace-small-max: 90px;

@mixin vspace-small(
	$top: true,
	$bottom: true,
	$type: margin,
	$modifier: 1,
	$media: true,
	$current: 9999
) {
	@include locked-spacing(
		$top,
		$bottom,
		$type,
		$modifier,
		$media,
		$current,
		$vspace-small-max,
		'vertical',
		$b-small,
		45px
	);
}

$vspace-xsmall-max: 65px;

@mixin vspace-xsmall(
	$top: true,
	$bottom: true,
	$type: margin,
	$modifier: 1,
	$media: true,
	$current: 9999
) {
	@include locked-spacing(
		$top,
		$bottom,
		$type,
		$modifier,
		$media,
		$current,
		$vspace-xsmall-max,
		'vertical',
		$b-small,
		30px
	);
}

$vspace-xxsmall-max: 52px;

@mixin vspace-xxsmall(
	$top: true,
	$bottom: true,
	$type: margin,
	$modifier: 1,
	$media: true,
	$current: 9999
) {
	@include locked-spacing(
		$top,
		$bottom,
		$type,
		$modifier,
		$media,
		$current,
		$vspace-xxsmall-max,
		'vertical',
		$b-small,
		25px
	);
}

$hspace-wrapper-max: 110px;

@mixin hspace-wrapper(
	$left: true,
	$right: true,
	$type: padding,
	$modifier: 1,
	$media: true,
	$current: 9999
) {
	@include locked-spacing(
		$left,
		$right,
		$type,
		$modifier,
		$media,
		$current,
		$hspace-wrapper-max,
		'horizontal',
		$b-minspacing
	);
}

$hspace-small-max: 90px;

@mixin hspace-small(
	$left: true,
	$right: true,
	$type: padding,
	$modifier: 1,
	$media: true,
	$current: 9999
) {
	@include locked-spacing(
		$left,
		$right,
		$type,
		$modifier,
		$media,
		$current,
		$hspace-small-max,
		'horizontal',
		$b-minspacing,
		30px
	);
}

@mixin maxwidth-wrapper($media: true, $content: $max-content, $current: 9999) {
	$hspace-wrapper-tween: strip-unit($hspace-wrapper-max) /
		strip-unit($b-maxspacing) * 100vw;
	$hspace-wrapper-min: strip-unit($hspace-wrapper-tween) / 100 * $b-small;

	@if $media {
		@if $current>$b-maxspacing {
			max-width: calc(#{$content} + #{$hspace-wrapper-max * 2});

			@media only screen and (max-width: $b-maxspacing) {
				max-width: calc(#{$content} + #{$hspace-wrapper-tween * 2});
			}

			@media only screen and (max-width: $b-small) {
				max-width: calc(#{$content} + #{$hspace-wrapper-min * 2});
			}
		} @else if $current>550 {
			max-width: calc(#{$content} + #{$hspace-wrapper-tween * 2});

			@media only screen and (max-width: $b-small) {
				max-width: calc(#{$content} + #{$hspace-wrapper-min * 2});
			}
		} @else {
			max-width: calc(#{$content} + #{$hspace-wrapper-min * 2});
		}
	} @else {
		@if $current>strip-unit($b-maxspacing) {
			max-width: calc(#{$content} + #{$hspace-wrapper-max * 2});
		} @else if $current>strip-unit($b-small) {
			max-width: calc(#{$content} + #{$hspace-wrapper-tween * 2});
		} @else {
			max-width: calc(#{$content} + #{$hspace-wrapper-min * 2});
		}
	}
}
