@mixin responsive-type($large: 1rem, $medium: 1rem, $small: 1rem, $media: true, $current: 9999) {
  @if $media {
    @if $current > strip-unit($b-medium) {
      font-size: $large;

      @media screen and (max-width: $b-medium) {
        font-size: $medium;
      }

      @media screen and (max-width: $b-small) {
        font-size: $small;
      }
    } @else if $current > strip-unit($b-small) {
      font-size: $medium;

      @media screen and (max-width: $b-small) {
        font-size: $small;
      }
    } @else if $current <= strip-unit($b-small) {
      font-size: $small;
    }
  } @else {
    @if $current > strip-unit($b-medium) {
      font-size: $large;
    } @else if $current > strip-unit($b-small) {
      font-size: $medium;
    } @else {
      font-size: $small;
    }
  }
}