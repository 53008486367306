.v-detail-two-column {
	background-color: $c-grey-light;
	display: flex;

	// for framer
	opacity: 0;

	&__main {
		position: relative;
		padding-top: 24px;
		background-color: $c-grey-light;
		color: $c-text;
		width: 57.5%;
		height: 100%;
		z-index: 1;
	}

	&__main-inner {
		position: relative;
		height: 100%;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			background-color: $c-grey-light;
			opacity: 1;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
	}

	&__title {
		// @include hspace-wrapper(true, true, padding, 1);
		padding: 0 calc(15px + 3.27510917%);
		margin-bottom: 1rem;
	}

	&__subtitle {
		// @include hspace-wrapper(true, true, padding, 1);
		padding: 0 calc(15px + 3.27510917%);
		margin-bottom: 1rem;
	}

	&__main-img-wrap {
		margin-left: 24px;
		margin-right: 24px;
		position: relative;
		height: 180px
	}

	&__main-img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 25%;
		border: 7px solid #fff;
	}

	&__main-content {
		@include vspace-xsmall(false, true);
		padding: 0 calc(15px + 3.27510917%);

		&:last-of-type {
			@include vspace-medium(false, true);
		}

		table,
		tr,
		td {
			 height: auto !important;
			 background-color: #fff;
		 }

		thead tr td {
			 background-color: #84736c;
		 }

		 .v-text-card--green {
				background-color: #e1f1e5;
				padding: 1rem .75rem;
				margin-bottom: .75rem;
		 }
	}

	&__sidebar {
		position: relative;
		@include vspace-small(true, true, padding);
		width: 42.5%;
		height: auto;
		background: linear-gradient(to right, #40638b, #186a39);
		min-height: calc(100vh - (64px + 43px)) // window height minus nav & footer height. (footer is a guess as it uses 'padding' to give it height)
	}

	&__sidebar-inner {
		position: sticky;
		top: calc(3.38542vw + 72px);
	}

	&__sidebar-cta {
		@include hspace-wrapper(true, true, padding, .5);
		margin: 0 auto;
		text-align: center;

		a {
			width: 90%;
		}
	}

	&__sidebar-content {
		@include hspace-wrapper(true, true, margin, .5);
		@include hspace-wrapper(true, true, padding, 1);
		@include vspace-medium(true, true, padding);
		@include vspace-xsmall(false, true);
		margin-top: 0;

		background-color: $c-teal;

		& a {
			color: #fff;
			text-decoration: underline;
		}
	}

	&__sidebar-inner > &__sidebar-cta + &__sidebar-content {
		@include vspace-medium(true, false);
	}

	&__card-content {
		padding: calc(3.27510917% + 15px) calc(15px + 3.27510917%) calc(15px + 6.55021834%);
		color: $c-text;

		& table th,
		& table a,
		& table em
		& .headers > strong {
			color: #fff !important;
		}
	}
}

@media screen and (max-width: $b-medium ) {
	.v-detail-two-column {
		flex-direction: column;

		&__main {
			width: 100%;
		}

		&__sidebar {
			width: 100%;
			min-height: auto;
		}
	}
}