.v-accordions {
  position: relative;
  z-index: 2;
  @include vspace-small;
  background: #fff;

  .container {
    padding: 0;
  }

  &--inline:first-child {
    margin-top: 0;
  }

  &--inline .container {
    @include hspace-wrapper;
  }

  &__inner {
    max-width: 65.625rem;
    margin: 0 auto;
  }

  &__title {
    color: $c-grey;
    text-transform: uppercase;
    font-size: 1rem;
    margin-top: calc(2rem + 3.133%);

    &:first-child {
      margin-top: 0;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    padding: 1.5rem 0;
    margin: -1px 0 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }

  &__question {
		// color: $c-primary;
		color: #007737;
    font-weight: 400;
    margin: 0;
    @include body-delta;
  }

  &__trigger {
    position: relative;
    display: block;
    width: 100%;
    padding: 1.5rem 0;
    margin: -1.5rem 0;
    font: inherit;
    font-size: 1em;
    text-align: left;
    color: inherit;
    background: none;
    border: 0;
    border-radius: 0;
    transition: background-color .15s ease-out;

    &:hover,
    &:focus,
    &[aria-expanded="true"] {
      background: rgba(0, 0, 0, .02);
    }
  }

  &__answer {
    display: none;
    height: 0;
    overflow: hidden;

    &.open {
      display: block;
      height: auto;
    }
  }

  &__answer-inner {
    padding: 3rem 0 0;

    ul, ol {
      padding-left: 2rem;
    }

    ul {
      list-style-type: disc;
    }
  }
}

@media screen and (max-width: $b-small) {
  .v-accordions {
    &__item {
      padding: 1rem 0;
    }

    &__trigger {
      padding: 1rem 0;
      margin: -1rem 0;
    }

    &__answer-inner {
      padding: 1rem 0 0;
    }
  }
}