.v-intro {
	@include vspace-xsmall(true, true, padding, .75);
	background-color: $c-grey-light;

	& .container {
		display: flex;
	}

	&__img-wrap {
		position: sticky;
		top: 100px;
		max-width: 25%;
		min-width: 250px;
		width: 100%;
		height: 250px;
		padding-bottom: 250px;
	}

	&__img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border: 7px solid #fff;
	}

	&__content {
		@include hspace-wrapper(true, false, padding)
		color: $c-text;
	}

	&__title {
		margin-top: .5rem;
	}
}

@media screen and (max-width: $b-medium ) {
	.v-intro {
		& .container {
			flex-direction: column;
		}

		&__img-wrap {
			position: relative;
			width: 100%;
			max-width: 100%;
			top: auto;
		}

		&__content {
			padding: 5.72917vw 0;
		}
	}
}