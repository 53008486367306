$hero-img-height: 85;

.v-slider {

	&__images {
		height: 200px;
		overflow: hidden;
	}

	&__slide {
		position: relative;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			background-color: #000;
			opacity: .25;
			width: 100%;
			height: 100%;
		}

		&-content {
			z-index: 1;
			margin-top: 1rem;
			display: flex;
			flex-direction: column;
			margin-top: 1.25rem;
		}

		&-title {
			text-align: center;
			margin-bottom: 1rem;
		}

		&-button {
			margin: 0 auto;
		}

	}

	&__img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100vw;
		height: 100%;
		object-fit: cover;
		object-position: 50% 25%;
	}

	&__dots {
		height: 44px;
		width: 100%;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__dot {
		padding: 0;
		width: 12px;
		height: 12px;
		margin: 0 5px;
		background: transparent;
		border: 0;
		border-radius: 50%;
		cursor: pointer;
		width: 20px;
		height: 20px;
		margin: 0 12px;
		border: 2px solid $c-primary;

		&--active {
			background-color: $c-primary;
		}
	}
}

@media ( orientation: portrait ) {
	.v-slider {
		&__images {
			height: 225px;
		}
	}
}