@mixin msedge() {
	@supports (-ms-ime-align: auto) {
		@content;
	}
}

@mixin ie11() {

	@media screen and (-ms-high-contrast: active),
	screen and (-ms-high-contrast: none) {
		@content;
	}
}

@mixin msedge_ie11() {
	@supports (-ms-ime-align: auto) {
		@content;
	}

	@media screen and (-ms-high-contrast: active),
	screen and (-ms-high-contrast: none) {
		@content;
	}
}