.v-content-table {
  @include vspace-small;

  & + & {
    @include vspace-small(true, false, margin, -.25);
  }

  &--inline {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .container {
      padding: 0;
    }

    .v-page-wrap & .container {
      @include hspace-wrapper;
    }
  }

  &__inner {
    max-width: 65.625rem;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 2.5rem;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    padding: calc(15px + 4.285714286%);
    margin: 0;
		background-color: #f7f7f7;

    &:nth-child(even) {
			background: #fff;
    }
  }

  &__header {
    width: 25.268817204%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 1;
    padding-left: calc(4.838709677% + 15px);

    & > ul {
      list-style: disc;

      & > li > ul {
        list-style: circle;
        margin-top: .25em;
      }
    }

    li {
      margin: .25em 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__header-title {
    color: #3f6589;
    font-weight: 400;
  }
}

@media screen and (max-width: 940px) {
  .v-content-table {
    &__item {
      display: block;
    }

    &__header {
      width: 100%;
    }

    &__content {
      padding: 0;
      margin: 1rem 0 0;
    }
  }
}

@media screen and (max-width: $b-small) {
  .v-content-table {
    .container {
      padding: 0;
    }

    .v-page-wrap & .container {
      padding: 0;
    }

    &__title,
    &__item {
      @include hspace-wrapper(true, true, padding, 1, true, $b-small);
    }

    &__title {
      margin-bottom: 1rem;
    }
  }
}